
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
export default {
  name: "Filters",
  props: {
    prop_brands: Array,
    prop_years: Array,
    prop_locations: Array,
    prop_fuel: Array,
    prop_type: Array,
    prop_trans: Array,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const priceValue = ref([0, 0]);
    const searchBrand = ref("");
    const searchState = ref("");
    const searchType = ref("");
    const years = ref<any>([]);
    const transmissions = ref<any>([]);
    const transmission = ref<string[]>([]);
    const fuelModel = ref<string[]>([]);
    const locationsModel = ref<string[]>([]);
    const cityModel = ref<string[]>([]);
    const brands = ref<any>([]);
    const type = ref<any>([]);
    const fuel = ref<any>([]);
    const locations = ref<any>([]);
    const priceFrom = ref("");
    const priceTo = ref("");
    const paramsQuery = ref({
      year: "",
      model: "",
      brand: "",
      type: "",
      fuel: "",
      transmission: "",
      price: "",
      state: "",
      city: "",
      search: "",
    });
    watch(
      () => route.query,
      () => {
        assingParams();
      }
    );
    const clearFilters = async () => {
      paramsQuery.value = {
        year: "",
        model: "",
        brand: "",
        type: "",
        fuel: "",
        transmission: "",
        price: "",
        state: "",
        city: "",
        search: "",
      };
      fuelModel.value = [];
      locationsModel.value = [];
      transmission.value = [];
      cityModel.value = [];
      priceFrom.value = "";
      priceTo.value = "";
      priceValue.value = [0, 0];

      router.replace({
        ...router.currentRoute,
        query: Object.fromEntries(
          Object.entries(paramsQuery.value).filter(([_, v]) => v != "")
        ),
      });
    };
    onMounted(() => {
      // Promise.all([getTypes(), getYears(), getBrands(), gettransmission(), getFuel(), getlocations()])
      type.value = props.prop_type;
      fuel.value = props.prop_fuel;
      locations.value = props.prop_locations;
      years.value = props.prop_years;
      brands.value = props.prop_brands;
      transmissions.value = props.prop_trans;
      assingParams();
    });
    const getRangeValues = (val) => {
      return val.replaceAll(" ", "").replaceAll(",", "").split("-");
    };

    const assingParams = () => {
      paramsQuery.value.year = route.query["year"] ? route.query["year"].toString() : "";
      paramsQuery.value.model = route.query["model"]
        ? route.query["model"].toString()
        : "";
      paramsQuery.value.price = route.query["price"]
        ? route.query["price"].toString()
        : "";
      paramsQuery.value.search = route.query["search"]
        ? route.query["search"].toString()
        : "";
      if (paramsQuery.value.price.length > 1) {
        const values = getRangeValues(paramsQuery.value.price);
        priceValue.value[0] = parseInt(values[0]);
        priceValue.value[1] = parseInt(values[1]);
        [priceFrom.value, priceTo.value] = priceValue.value.toString().split(",");
        priceFrom.value = formatTooltip(priceFrom.value);
        priceTo.value = formatTooltip(priceTo.value);
      }
      paramsQuery.value.brand = route.query["brand"]
        ? route.query["brand"].toString()
        : "";
      paramsQuery.value.fuel = route.query["fuel"] ? route.query["fuel"].toString() : "";
      paramsQuery.value.transmission = route.query["transmission"]
        ? route.query["transmission"].toString()
        : "";
      paramsQuery.value.type = route.query["type"] ? route.query["type"].toString() : "";
      paramsQuery.value.city = route.query["city"] ? route.query["city"].toString() : "";
      paramsQuery.value.state = route.query["state"]
        ? route.query["state"].toString()
        : "";

      if (paramsQuery.value.transmission.length > 1) {
        transmission.value = paramsQuery.value.transmission.split(",");
      }
      if (paramsQuery.value.fuel.length > 1) {
        fuelModel.value = paramsQuery.value.fuel.split(",");
      }

      if (paramsQuery.value.state.length > 1) {
        locationsModel.value = paramsQuery.value.state.split(",");
      }
      if (paramsQuery.value.city.length > 1) {
        cityModel.value = paramsQuery.value.city.split(",");
      }
    };
    const applyFilter = (type: string, value: string, functionality: string) => {
      // replace, push, push-child
      if (functionality == "replace") {
        value = value.toString();
        if (paramsQuery.value[type].includes(value)) {
          paramsQuery.value[type] = "";
          if (type == "state") {
            locationsModel.value = locationsModel.value.filter((l) => l != value);
          } else if (type == "fuel") {
            fuelModel.value = fuelModel.value.filter((l) => l != value);
          } else if (type == "transmission") {
            transmission.value = transmission.value.filter((l) => l != value);
          }
        } else {
          paramsQuery.value[type] =
            value.split(",").length > 1 ? value.split(",")[1] : value.split(",")[0];
          if (type == "state") {
            locationsModel.value = [
              value.split(",").length > 1 ? value.split(",")[1] : value.split(",")[0],
            ];
          } else if (type == "fuel") {
            fuelModel.value = [
              value.split(",").length > 1 ? value.split(",")[1] : value.split(",")[0],
            ];
          } else if (type == "transmission") {
            transmission.value = [
              value.split(",").length > 1 ? value.split(",")[1] : value.split(",")[0],
            ];
          }
        }
      } else if (functionality == "push") {
        value = value.toString();

        if (type == "state" || type == "city") {
          let explodedParamsQuerty: any = [];
          const valuesData = value.split(",");
          if (valuesData.length > 0) {
            valuesData.forEach((val) => {
              explodedParamsQuerty.push(val);
              paramsQuery.value[type] = explodedParamsQuerty.toString();
            });
          } else {
            paramsQuery.value[type] = "";
          }
        } else {
          let explodedParamsQuerty =
            paramsQuery.value[type].length > 0 ? paramsQuery.value[type].split(",") : [];
          if (explodedParamsQuerty.includes(value.toString())) {
            explodedParamsQuerty = explodedParamsQuerty.filter((e) => e != value);
          } else {
            explodedParamsQuerty.push(value);
          }
          paramsQuery.value[type] = explodedParamsQuerty.toString();
        }
      } else if (functionality == "push-child") {
        if (type == "brand") {
          {
            let explodedParamsQuerty =
              paramsQuery.value.brand.length > 0
                ? paramsQuery.value.brand.split(",")
                : [];
            if (explodedParamsQuerty.includes(value)) {
              explodedParamsQuerty = explodedParamsQuerty.filter((e) => e != value);
              let models = brands.value.find((m) => m.brand == value)?.models;
              let explodedParamsQueryModels =
                paramsQuery.value.model.length > 0
                  ? paramsQuery.value.model.split(",")
                  : [];

              explodedParamsQueryModels = explodedParamsQueryModels.filter(
                (m) => !models?.some((_m) => _m.model == m)
              );
              paramsQuery.value.model = explodedParamsQueryModels.toString();
            } else {
              explodedParamsQuerty.push(value);
            }
            paramsQuery.value.brand = explodedParamsQuerty.toString();
          }
        }
      } else if (functionality == "range") {
        if (type == "price") {
          {
            [priceFrom.value, priceTo.value] = value.split(",");
            priceFrom.value = formatTooltip(priceFrom.value);
            priceTo.value = formatTooltip(priceTo.value);
            paramsQuery.value.price = value.replace(",", "-");
          }
        }
      }
      router.replace({
        ...router.currentRoute,
        query: Object.fromEntries(
          Object.entries(paramsQuery.value).filter(([_, v]) => v != "")
        ),
      });
    };

    const formatTooltip = (val) => {
      val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$ " + val;
    };
    const changePrice = () => {
      priceValue.value[0] = !isNaN(
        parseInt(
          priceFrom.value
            .trim()
            .replaceAll("$", "")
            .replaceAll(",", "")
            .replaceAll(" ", "")
        )
      )
        ? parseInt(
            priceFrom.value
              .trim()
              .replaceAll("$", "")
              .replaceAll(",", "")
              .replaceAll(" ", "")
          )
        : 0;
      priceValue.value[1] = !isNaN(
        parseInt(
          priceTo.value.trim().replaceAll("$", "").replaceAll(",", "").replaceAll(" ", "")
        )
      )
        ? parseInt(
            priceTo.value
              .trim()
              .replaceAll("$", "")
              .replaceAll(",", "")
              .replaceAll(" ", "")
          )
        : 0;
      if (priceValue.value[0] > priceValue.value[1]) {
        priceValue.value[1] = priceValue.value[0];
        priceValue.value[1] = priceValue.value[0];
        priceTo.value + priceFrom.value;
      }
      applyFilter("price", priceValue.value.toString(), "range");
    };

    const searchedBrands = computed(() => {
      return brands.value.filter((y) => {
        return y.brand.toLowerCase().includes(searchBrand.value.toLowerCase());
      });
    });
    const searchedStates = computed(() => {
      return locations.value.filter((y) => {
        return y.state.toLowerCase().includes(searchState.value.toLowerCase());
      });
    });
    const searchedTypes = computed(() => {
      return type.value.filter((y) => {
        return y.type.toLowerCase().includes(searchType.value.toLowerCase());
      });
    });

    return {
      years,
      searchedBrands,
      searchBrand,
      applyFilter,
      paramsQuery,
      priceValue,
      searchType,
      searchedTypes,
      type,
      priceFrom,
      priceTo,
      changePrice,
      formatTooltip,
      transmissions,
      transmission,
      fuel,
      fuelModel,
      locationsModel,
      locations,
      clearFilters,
      searchState,
      searchedStates,
      cityModel,
    };
  },
};
