
import { ref, watch, onMounted, onBeforeMount } from "vue";
import Filters from "./components/Filters.vue";
import Carousel from "@/views/client/components/Carousel.vue";
import { useRoute, useRouter } from "vue-router";
import CardVehicle from "./components/CardVehicle.vue";
import ApiService from '@/core/services/ApiService';
import { useStore } from 'vuex';
import { Actions } from '@/store/enums/StoreEnums';
import _ from 'lodash';
export default {
  name: "Catalogo",
  components: {
    Filters,
    CardVehicle,
    Carousel
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const typeCard = ref("cards");
    const order = ref<number | null>(null);
    const brands = ref<any>(null);
    const brandsAll = ref<any>(null);
    const years = ref<any>(null);
    const transmissions = ref<any>(null);
    const type = ref<any>(null);
    const fuel = ref<any>(null);
    const locations = ref<any>(null);
    const vehicles = ref<any[]>([]);
    const similares = ref<any[]>([]);
    const paginate = ref({
      current_page: 1,
      per_page: 9,
      total: 0,
    });
    const searchGlobal = ref<string>("");
    const paramsQuery = ref({
      year: "",
      model: "",
      brand: "",
      type: "",
      fuel: "",
      transmission: "",
      price: "",
      state: "",
      city: "",
      search: ""
    });
    const classList = ref("col-xl-8 col-lg-8 px-3 col-xxl-9");
    const classGrid = ref("col-xl-4 col-lg-6 col-md-6 col-12");
    watch(
      () => route.query,
      () => {
        assingParams();
        getVehicles();
      }
    );
    const currentPageChange = async(val) => {
        paginate.value.current_page = val;
        await getVehicles();
    }
    const changeGrid = (typeGrid) => {
      switch (typeGrid) {
        case "list":
          classGrid.value = "col-12";
          break;
        case "cards":
          classGrid.value = "col-lg-4 col-md-6 col-12";
          break;
        default:
          break;
      }
      typeCard.value = typeGrid;
    };

    const searchDebouncer = _.debounce(()=>{
      if(searchGlobal.value.length > 0){
        router.replace({
          ...router.currentRoute,
          query: {...route.query, search: searchGlobal.value},
        });
      }else{
        paramsQuery.value.search = "";
        router.replace({
          ...router.currentRoute,
          query: Object.fromEntries(
            Object.entries(paramsQuery.value).filter(([_, v]) => v != "")
          ),
        });
      }
      getVehicles()
      },500) 

    const assingParams = () => {
      paramsQuery.value.year = route.query["year"]
        ? route.query["year"].toString()
        : "";
      paramsQuery.value.model = route.query["model"]
        ? route.query["model"].toString()
        : "";
      paramsQuery.value.price = route.query["price"]
        ? route.query["price"].toString()
        : "";
      paramsQuery.value.brand = route.query["brand"]
        ? route.query["brand"].toString()
        : "";
      paramsQuery.value.fuel = route.query["fuel"]
        ? route.query["fuel"].toString()
        : "";
      paramsQuery.value.transmission = route.query["transmission"]
        ? route.query["transmission"].toString()
        : "";
      paramsQuery.value.state = route.query["state"]
        ? route.query["state"].toString()
        : "";
      paramsQuery.value.city = route.query["city"]
        ? route.query["city"].toString()
        : "";
      searchGlobal.value = route.query["search"]
        ? route.query["search"].toString()
        : "";
      paramsQuery.value.type = route.query["type"]
        ? route.query["type"].toString()
        : "";
    };

    const getVehicles = async ()=> {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

      try {
        let url = "/api/vehicle-filters?showing=" + paginate.value.per_page + '&page=' + paginate.value.current_page;
        for (const p in paramsQuery.value) {
          const actual  =  paramsQuery.value[p];
          if(p == "brand" && actual.length > 0){
            actual.split(',').forEach((element, index) => {
              url += `&${p}=`
              url += findBrandByName(element)
            });
          }
          else if(p == "price" && actual.length > 0){
            const price_from = parseInt(actual.replaceAll(' ', '').replaceAll(',', '').split('-')[0]);
            const price_to = parseInt(actual.replaceAll(' ', '').replaceAll(',', '').split('-')[1]);
            url += `&price_from=${price_from}&price_to=${price_to}`;
          }
          else if(p == "km" && actual.length > 0){
            const km_from = parseInt(actual.replaceAll(' ', '').replaceAll(',', '').split('-')[0]);
            const km_to = parseInt(actual.replaceAll(' ', '').replaceAll(',', '').split('-')[1]);
            url += `&km_from=${km_from}&km_to=${km_to}`;
          }
          else if(p == "model" && actual.length > 0){
            actual.split(',').forEach((element, index) => {
              url += `&${p}=`;
              url += findModelByName(element)
            });
          }
          else if(p == "fuel" && actual.length > 0){
            actual.split(',').forEach((element, index) => {
              url += `&${p}=`;
              url += findFuelByName(element)
            });
          }
          else if(p == "type" && actual.length > 0){
            actual.split(',').forEach((element, index) => {
              url += `&${p}=`;
              url += findTypeByName(element)
            });
          }
          else if(p == "transmission" && actual.length > 0){
            actual.split(',').forEach((element, index) => {
              url += `&${p}=`;
              url += findTransByName(element)
            });
          }
          else if(p == "state" && actual.length > 0){
            actual.split(',').forEach((element, index) => {
              url += `&${'location'}=`;
              url += findLocByName(element)
            });
          }
          else if(actual.length > 0){
            actual.split(',').forEach((element, index) => {
              url += `&${p}=`
              url += element;
            });
          }
        }
        if( searchGlobal.value ){
          url += '&search=' +searchGlobal.value ;
        }
        if(order.value == 1){
          url += '&orderbyprice=asc';
        }
        else if(order.value == 2){
          url += '&orderbyprice=desc';
        }
        else if(order.value == 3){
          url += '&orderbykm=asc';
        }
        else if(order.value == 4){
          url += '&orderbykm=desc';
        }
        else if(order.value == 5){
          url += '&orderbyyear=asc';
        }
        else if(order.value == 6){
          url += '&orderbyyear=desc';
        }
        const {data} = await ApiService.get(url);
        vehicles.value = data.data.Vehicles.data;
        similares.value = data.data.Similar.data.length > 2 ? data.data.Similar.data.slice(0,3) : data.data.Similar.data;
        paginate.value.current_page = data.data.Vehicles.current_page;
        paginate.value.per_page = data.data.Vehicles.per_page;
        paginate.value.total = data.data.Vehicles.total;
      } catch (response) {
        console.error(response, 'aqui');
      }
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    }
    const getTypes = async() => {
      try {
        const { data } = await ApiService.get("/api/site/types");
        console.log("types");
        console.log(data);
        type.value = data.data;
      } catch (error) {
        console.log(error);
      }
    }
    const getlocations = async() => {
      try {
        const { data } = await ApiService.get("/api/site/locations");
        locations.value = Object.values(data.data);
        // console.log("locations");
        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
    const getFuel = async() => {
      try {
        const { data } = await ApiService.get("/api/site/fuels");
        fuel.value = data.data;
      } catch (error) {
        console.log(error);
      }
    }
    const getYears = async() => {
      try {
        const { data } = await ApiService.get("/api/site/years");
        years.value = data.data.map(y => y.year);
      } catch (error) {
        console.log(error);
      }
    }
    const getBrands = async() => {
      try {
        const { data } = await ApiService.get("/api/site/brands-model");
        brands.value = Object.values(data.data).map((e:any) => {
          if(e){
            e.models = Object.values(e.models)
          }
          return e;
        })
      } catch (error) {
        console.log(error);
      }
    }
    const getBrandsAll = async() => {
      try {
        const { data } = await ApiService.get("/api/brands");
        // console.log("all", data)
        brandsAll.value = Object.values(data.data).map((e:any) => e)
      } catch (error) {
        console.log(error);
      }
    }
    const gettransmission = async() => {
      try {
        const { data } = await ApiService.get("/api/site/transmissions");
        transmissions.value = data.data;
      } catch (error) {
        console.log(error);
      }
    }

    const findBrandByName = (name) => {

      if(brands.value.find(b => b.brand == name)) {
        // console.log("entro en inventario")
        return brands.value.find(b => b.brand == name)!.id;
      } else {
        console.log("entro en catalogo")
        return brandsAll.value.find(b => b.name == name)!.id;
      }
    }
    const findModelByName = (name) => {
      let ret = 0;
      brands.value.forEach(element => {
        element.models.forEach(_element => {
          if(_element.model == name){
            ret = _element.id
          }
        });
      });
      console.log(ret, 'ret')
      return ret
    }
    const findFuelByName = (name) => {
      return fuel.value.find(b => b.fuel == name)!.id;
    }
    const findTypeByName = (name) => {
      return type.value.find(b => b.type == name)!.id;
    }
    const findTransByName = (name) => {
      return transmissions.value.find(b => b.type == name).id;
    }
    const findLocByName = (name) => {
      return locations.value.find(b => b.state == name).id;
    }
    onMounted(async() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
      await Promise.all([getTypes(), getYears(), getBrands(), gettransmission(), getFuel(), getlocations(), getBrandsAll()])
      assingParams(); 
      await getVehicles();
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    });



    return {
      searchGlobal,
      currentPageChange,
      classList,
      paramsQuery,
      typeCard,
      order,
      vehicles,
      paginate,
      classGrid,
      changeGrid,
      fuel,
      locations,
      type,
      years,
      brands,
      similares,
      transmissions,
      getVehicles,
      searchDebouncer,
      brandsAll
    };
  },
};
